a {
    color: inherit;
    text-decoration: none;
}

input {
    border-radius: 5px;
    font-size: 18px;
    padding: 10px;
    margin: 5px 0px;
}

.btn {
    font-size: 18px;
    padding: 12px;
    background-color: #34b27b;
    border-width: 0;
    border-radius: 5px;
    color: #f9fdfb;
    text-align: center;
}

.btn:active {
    background-color: #546A7B;
}

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #282828; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}