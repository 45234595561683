html,
body,
#root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  width: 100vw;
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  margin: 0;
  line-height: 1.5;
  background-color: #1c1c1c;
  color: #f9fdfb;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  overflow: hidden;
}

